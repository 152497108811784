exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-immersive-digital-solutions-our-solutions-js": () => import("./../../../src/pages/immersive-digital-solutions/our-solutions.js" /* webpackChunkName: "component---src-pages-immersive-digital-solutions-our-solutions-js" */),
  "component---src-pages-immersive-digital-solutions-workshops-js": () => import("./../../../src/pages/immersive-digital-solutions/workshops.js" /* webpackChunkName: "component---src-pages-immersive-digital-solutions-workshops-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-workplace-compliance-js": () => import("./../../../src/pages/workplace/compliance.js" /* webpackChunkName: "component---src-pages-workplace-compliance-js" */),
  "component---src-pages-workplace-js": () => import("./../../../src/pages/workplace.js" /* webpackChunkName: "component---src-pages-workplace-js" */)
}

